import { MDXProvider } from '@mdx-js/react';
import React, { PropsWithChildren } from 'react';
import Layout from './layout';
import * as style from './layout-mdx.module.scss';

const slugify = (input: string) =>
  input
    .replace(/[":;\/\\'`]/gi, '')
    .replace(/\s/gi, '-')
    .toLowerCase();

export default function LayoutMDX({
  children,
  pageContext
}: PropsWithChildren<{ pageContext: { frontmatter: any } }>) {
  return (
    <Layout pageTitle={pageContext.frontmatter.title}>
      <section
        className={
          style.page + ' m-auto d-md-flex flex-column align-items-center justify-content-center'
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <MDXProvider
                components={{
                  h1: (props) => <h1 id={slugify(props.children)}>{props.children}</h1>,
                  h2: (props) => <h2 id={slugify(props.children)}>{props.children}</h2>,
                  h3: (props) => <h3 id={slugify(props.children)}>{props.children}</h3>,
                  h4: (props) => <h4 id={slugify(props.children)}>{props.children}</h4>
                }}
              >
                {children}
              </MDXProvider>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
